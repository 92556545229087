import styled from "styled-components";
import { useSelector } from "react-redux";
import { useModal } from "../../hooks/useModal";
import { FOOTER_FOREGROUND, FUNNEL_DESIGNS } from "../../templates/strings";

const FooterAnchor = styled.a`
  cursor: pointer;
  text-decoration: ${({ shouldUnderline }) =>
    shouldUnderline ? "underline" : "none"};
  font-weight: ${({ fw }) => (fw ? fw : "normal")};
  font-size: ${({ design, color, fs }) =>
    fs
      ? `${fs}px`
      : design === FUNNEL_DESIGNS.MODERN && !color
        ? "16px"
        : "12px"};
  color: ${({ design, footerForeground, color }) =>
    color
      ? color
      : design === FUNNEL_DESIGNS.MODERN
        ? "#adadad"
        : footerForeground === FOOTER_FOREGROUND.SHADOWED
          ? "#fff"
          : footerForeground};
  &:hover {
    color: ${({ design, footerForeground, color }) =>
      color
        ? color
        : design === FUNNEL_DESIGNS.MODERN
          ? "#adadad"
          : footerForeground === FOOTER_FOREGROUND.SHADOWED
            ? "#fff"
            : footerForeground};
  }
`;

const AnchorElement = ({
  href,
  modalData,
  anchorText,
  footerForeground,
  classes = "",
  color = null,
  fs = null,
  fw = null,
  shouldUnderline = false
}) => {
  const { funnel } = useSelector((state) => state.funnelInfo);
  const { handleModalData } = useModal();
  const handleModal = (data) => {
    handleModalData({ ...data, show: true });
  };
  return (
    <FooterAnchor
      fs={fs}
      fw={fw}
      href={href}
      color={color}
      target="_blank"
      rel="noreferrer"
      className={classes}
      design={funnel.funnelDesign}
      shouldUnderline={shouldUnderline}
      onClick={() => handleModal(modalData)}
      footerForeground={footerForeground}
    >
      {anchorText}
    </FooterAnchor>
  );
};
export default AnchorElement;
