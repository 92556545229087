import styled from "styled-components";

const Main = styled.div`
  font-family: "Twemoji Country Flags", "Roboto" !important;
`;
const Header = styled.div`
  height: 72px;
  background-color: ${({ bg }) => bg};
`;
const HeaderImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;
const HeadingsContainer = styled.div`
  padding: 1.5rem 1rem 0.5rem 1rem;
`;
const PageHeadline = styled.div`
  color: ${({ color }) => color};
  font-size: 36px;
  line-height: 1.15;
  letter-spacing: -0.02em;
  @media (max-width: 481px) {
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: normal;
  }
`;
const SecondHeadline = styled.div`
  color: ${({ color }) => color};
  font-size: 26px;
  line-height: 1.15;
  letter-spacing: -0.02em;
  @media (max-width: 481px) {
    font-size: 18px;
    line-height: 1.25;
    letter-spacing: normal;
  }
`;
const ImageContainer = styled.div`
  max-width: 36rem;
  @media (max-width: 481px) {
    max-width: 28rem;
  }
`;
const HeroImageContainer = styled.div`
  padding: 2.5rem 5rem;
  @media (max-width: 481px) {
    padding: 1.5rem;
  }
`;
const HeroImage = styled.img`
  height: auto;
  max-width: 100%;
  border-radius: 0.75rem;
`;
const HeroContentContainer = styled.div`
  padding: 1rem 2rem;
`;
const ContentInner = styled.div`
  margin: 0 auto;
  max-width: 56rem;
  @media (max-width: 481px) {
    max-width: 28rem;
  }
`;
const EditorContent = styled.div`
  * {
    margin: 0 !important;
    padding: 0 !important;
    white-space: pre-wrap !important;
  }
`;
const CTAContainer = styled.div`
  padding: 2rem 1.5rem;
  @media (max-width: 481px) {
    padding: 0 1.5rem;
  }
`;
const CTAbutton = styled.button`
  width: auto;
  max-width: 28rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: ${({ color }) => color};
  background-color: ${({ bg }) => bg};
  @media (max-width: 481px) {
    width: 100%;
    padding: 1rem;
  }
`;
const CTAbuttonText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  @media (max-width: 481px) {
    font-size: 18px;
  }
`;
const SpearatorContainer = styled.div`
  padding: 2rem;
  @media (max-width: 481px) {
    padding: 0.3rem 2rem 1rem 2rem;
  }
`;
const SeparatorInner = styled.div`
  height: 0;
  padding: 1.25rem 0;
`;
const Separator = styled.div`
  border-top: 1px solid #000;
`;
const BenefitsHeadingsContainer = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
`;
const BenefitsHeadline = styled.div`
  font-size: 32px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ color }) => color};
  @media (max-width: 481px) {
    font-size: 24px;
  }
`;
const BenefitsGridContainer = styled.div`
  padding: 1rem 1.5rem 1.5rem 1.5rem;
`;
const BenefitLabel = styled.h4`
  margin-bottom: 5px;
  font-size: inherit;
`;
const BenefitSubtext = styled.p`
  margin: 0;
  font-size: inherit;
`;
const ContentContainer = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`;
const GridCover = styled.div`
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media (max-width: 481px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;
const EmojiCover = styled.div`
  width: 48px;
  height: 48px;
  font-size: 32px;
`;
const BenefitTextCover = styled.div`
  display: flex;
  font-size: 16px;
  min-height: 48px;
  margin-left: 10px;
  overflow-wrap: break-word;
  @media (max-width: 481px) {
    display: block;
    margin-left: 5px;
  }
`;
const FooterContainer = styled.div`
  max-width: 1536px;
`;
const FooterPadder = styled.div`
  padding: 0 3rem;
  @media (max-width: 481px) {
    padding: 0 1rem;
  }
`;
const FooterInner = styled.div`
  padding: 1.5rem;
  @media (max-width: 481px) {
    padding: 0.5rem;
  }
`;
const FooterEditorContainer = styled.div`
  padding: 1.5rem 0;
`;
const PolicySection = styled.div`
  padding: 1rem;
`;
const PolicyText = styled.div`
  line-height: 1.25rem;
  font-family:
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    Segoe UI Symbol,
    "Noto Color Emoji" !important;
  @media (max-width: 481px) {
    line-height: 1rem;
  }
`;
const PolicyTextDivider = styled.span`
  padding: 0px 0.575rem;
`;

export {
  Main,
  Header,
  HeaderImg,
  HeroImage,
  CTAbutton,
  Separator,
  GridCover,
  EmojiCover,
  PolicyText,
  FooterInner,
  FooterPadder,
  BenefitLabel,
  PageHeadline,
  ContentInner,
  CTAContainer,
  EditorContent,
  CTAbuttonText,
  PolicySection,
  SecondHeadline,
  BenefitSubtext,
  ImageContainer,
  SeparatorInner,
  FooterContainer,
  BenefitTextCover,
  BenefitsHeadline,
  ContentContainer,
  PolicyTextDivider,
  HeadingsContainer,
  HeroImageContainer,
  SpearatorContainer,
  HeroContentContainer,
  BenefitsGridContainer,
  FooterEditorContainer,
  BenefitsHeadingsContainer
};
