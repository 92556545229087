import { useSelector, useDispatch } from "react-redux";
import { ContentInner, CTAbutton, CTAbuttonText, CTAContainer } from "./styles";
import { setRecordActionAndHasLandingPageStates } from "../../../../store/slices/funnelInfo";

const CTABtnComponent = () => {
  const dispatch = useDispatch();
  const { funnel } = useSelector((state) => state.funnelInfo);
  const { landingPageId: landingPage } = funnel;
  return (
    <CTAContainer className="text-center">
      <ContentInner>
        <CTAbutton
          className="fw-bold border-0 mx-auto text-center"
          bg={landingPage.ctaButtonBackgroundColor}
          color={landingPage.ctaButtonColor}
          onClick={() => {
            dispatch(setRecordActionAndHasLandingPageStates());
          }}
        >
          <CTAbuttonText className="mx-auto text-center">
            {landingPage.ctaButtonText}
          </CTAbuttonText>
        </CTAbutton>
      </ContentInner>
    </CTAContainer>
  );
};
export default CTABtnComponent;
