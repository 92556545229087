import {
  BenefitsInnerCol,
  BenefitIconWrapper,
  BenefitContentWrapper
} from "./styles";

const BenefitsColumn = ({ item }) => {
  return (
    <BenefitsInnerCol xs={12}>
      <BenefitContentWrapper className="d-flex align-items-center text-white">
        <BenefitIconWrapper>{item.icon}</BenefitIconWrapper>
        <div className="fw-bold">{item.label}</div>
      </BenefitContentWrapper>
    </BenefitsInnerCol>
  );
};
export default BenefitsColumn;
