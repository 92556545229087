import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { CTAButton } from "./styles";
import { setRecordActionAndHasLandingPageStates } from "../../../../store/slices/funnelInfo";

const CTAButtonComponent = () => {
  const dispatch = useDispatch();
  const { funnel } = useSelector((state) => state.funnelInfo);
  const { landingPageId: landingPage } = funnel;

  return (
    <CTAButton
      bg={landingPage.ctaButtonBackgroundColor}
      color={landingPage.ctaButtonColor}
      onClick={() => {
        dispatch(setRecordActionAndHasLandingPageStates());
      }}
      className="fw-bold text-decoration-none border-0 outline-0 d-inline-block"
    >
      <span>{landingPage.ctaButtonText}</span>
      <span className="ms-2">
        <FontAwesomeIcon icon={faAnglesRight} />
      </span>
    </CTAButton>
  );
};
export default CTAButtonComponent;
