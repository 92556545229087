import { useSelector } from "react-redux";
import ReadOnlyModal from "../../Modal";
import { FooterContainer } from "../ModernVariant.styles";
import AnchorElement from "../../CommonVariantComponents/anchor-element";

const FooterComponent = () => {
  const { funnel } = useSelector((state) => state.funnelInfo);
  const company = funnel.companyInfo;
  const design = funnel.design;
  return (
    <>
      <FooterContainer className="d-flex align-items-center justify-content-between w-100 px-4 position-absolute">
        <div>
          © {company?.companyName} {new Date().getFullYear()}
        </div>
        <div>
          <AnchorElement
            classes="me-3"
            anchorText="Impressum"
            modalData={{
              heading: "Impressum",
              content: company?.imprintContent
            }}
            href={company?.imprintContent ? void 0 : company?.imprintUrl}
            footerForeground={design?.footerForeground}
          />
          <AnchorElement
            anchorText="Datenschutz"
            modalData={{
              heading: "Datenschutzerklärung",
              content: company?.dataProtectionContent
            }}
            href={
              company?.dataProtectionContent
                ? void 0
                : company?.dataProtectionUrl
            }
            footerForeground={design?.footerForeground}
          />
        </div>
      </FooterContainer>
      <ReadOnlyModal />
    </>
  );
};
export default FooterComponent;
