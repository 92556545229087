import styled from "styled-components";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import AnchorElement from "../CommonVariantComponents/anchor-element";

const ConfirmationLabel = styled.label`
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  line-height: 15px;
`;

export const PolicySection = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const { funnel } = useSelector((state) => state.funnelInfo);

  return (
    <div
      className={`mt-4 ${funnel.design?.policyDisplay ? "d-flex" : "d-none"}`}
    >
      <Form.Check type="checkbox" {...register("policyCheck")} />
      <ConfirmationLabel
        className={`mt-1 ${errors?.policyCheck ? "text-danger" : ""}`}
      >
        {funnel.design?.policyText ? (
          <div
            dangerouslySetInnerHTML={{
              __html: funnel.design?.policyText
            }}
          ></div>
        ) : (
          // default text as mentioned in modern ui design
          <div>
            Ja, ich habe die{" "}
            <AnchorElement
              anchorText="Datenschutzerklärung"
              modalData={{
                heading: "Datenschutzerklärung",
                content: funnel.companyInfo?.dataProtectionContent
              }}
              color={funnel.design?.modernBackgroundOne}
              href={
                funnel.companyInfo?.dataProtectionContent
                  ? void 0
                  : funnel.companyInfo?.dataProtectionUrl
              }
              footerForeground={funnel.design?.footerForeground}
            />{" "}
            zur Kenntnis genommen und bin damit einverstanden, dass die von mir
            angegebenen Daten elektronisch erhoben und gespeichert werden. Meine
            Daten werden hierbei nur streng zweckgebunden zur Bearbeitung und
            Beantwortung meiner Anfrage genutzt.
          </div>
        )}
      </ConfirmationLabel>
    </div>
  );
};
