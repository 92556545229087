import { useSelector } from "react-redux";
import ReadOnlyModal from "../Modal";
import TemplateOne from "./LandingPageTemplates/TemplateOne";
import TemplateTwo from "./LandingPageTemplates/TemplateTwo";
import { LANDING_PAGE_TEMPLATES } from "../../templates/strings";

const LandingPage = () => {
  const { FUNNEL_LANDING_PAGE_TEMP_ONE, FUNNEL_LANDING_PAGE_TEMP_TWO } =
    LANDING_PAGE_TEMPLATES;
  const { funnel } = useSelector((state) => state.funnelInfo);

  const generatedTemplate = (templateType) => {
    let template;
    switch (templateType) {
      case FUNNEL_LANDING_PAGE_TEMP_ONE:
        template = <TemplateOne />;
        break;
      case FUNNEL_LANDING_PAGE_TEMP_TWO:
        template = <TemplateTwo />;
        break;
      default:
        break;
    }
    return template;
  };

  return (
    <>
      <div>{generatedTemplate(funnel.landingPageTemplateType)}</div>
      <ReadOnlyModal />
    </>
  );
};
export default LandingPage;
