import { useEffect, useState } from "react";

// for media query of <= 481 only!
const useCheckMobileScreen = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return screenWidth <= 481;
};

export default useCheckMobileScreen;
